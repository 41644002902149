import actionCreatorFactory from 'typescript-fsa';
import { IUserDataState } from '../reducers/userDataReducer';
import { IShareProgramEmailActionType, IShareProgramSmsActionType, MergeErrorString } from '../reducers/shareProgramReducer';
import { LanguageCode, MyTreatmentProgramDescription } from '@visikon/core-models/content';
import { Country } from '@visikon/core-models/i18n/languages';
import { NavLink } from '../reducers/resourcesReducer';

type ObjectId = string;

const actionCreator = actionCreatorFactory('userActions');

// Share
export const shareProgramSms = actionCreator<IShareProgramSmsActionType>('SHARE_PROGRAM_SMS');
export const shareProgramEmail = actionCreator<IShareProgramEmailActionType>('SHARE_PROGRAM_EMAIL');

export const shareProgramSuccess = actionCreator('SHARE_PROGRAM_SUCCESS');
export const shareProgramFailed = actionCreator<string>('SHARE_PROGRAM_FAILED');
export const shareProgramClearStatus = actionCreator('SHARE_PROGRAM_CLEAR_STATUS');

// Merge
export const mergeProgram = actionCreator<{ code: string; country?: Country }>('MERGE_PROGRAM');
export const mergeProgramFailed = actionCreator<{ error: MergeErrorString; availableLanguages?: LanguageCode[] }>('MERGE_PROGRAM_FAILED');
export const mergeProgramReset = actionCreator('MERGE_PROGRAM_RESET');
// Privacy
export const setSeenPrivacyPolicy = actionCreator<Date>('SET_SEEN_PRIVACY_POLICY');
export const resetSeenPrivacyPolicy = actionCreator('RESET_SEEN_PRIVACY_POLICY');
// Progress on videos
export const updateVideoProgress = actionCreator<{ id: string; percentage: number }>('UPDATE_VIDEO_PROGRESS');

// Favorites
export const toggleFavorite = actionCreator<ObjectId>('TOGGLE_FAVORITE');

// Handle login/logout
export const initializeUserInfo = actionCreator<IUserDataState>('INITIALIZE_USER_DATA');
export const clearUserData = actionCreator('CLEAR_USER_DATA');

export const setOperationDate = actionCreator<{ programId: string; operationDate: number }>('SET_OPERATION_DATE');
export const setUserData = actionCreator<IUserDataState>('SET_USER_DATA');
export const setProgramsData = actionCreator<MyTreatmentProgramDescription[]>('SET_PROGRAMS_DATA');
export const archiveProgram = actionCreator<string>('ARCHIVE_PROGRAM');
export const restoreProgram = actionCreator<string>('RESTORE_PROGRAM');
export const setNewProgram = actionCreator<string>('SET_NEW_PROGRAM');
export const unsetNewProgram = actionCreator<string>('UNSET_NEW_PROGRAM');
export const setNavLink = actionCreator<NavLink>('SET_NAV_TITLE');
export const unsetNavLink = actionCreator('UNSET_NAV_TITLE');
