import * as React from 'react';
import { ActionCard, Button, Typography } from '@visikon/spine';
import { PresentDatePicker } from './DatePickerUtil';
import { DateTime } from 'luxon';
import { mapLanguageToLocale } from '@visikon/utils/src/language';
import { ProgramInfo } from '@visikon/backbone/src/models';
import { useBackbone } from '@visikon/backbone/src';
import { LanguageCode } from '@visikon/core-models/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnvironmentName } from '@visikon/backbone/src/env';
import * as Styles from './ProgramHeader.styles';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { TextKeys } from '@visikon/core-models/i18n/translations';

// TODO: shared libraries shouldn't import stuff from mytreatment
import { I18N } from '@visikon/mytreatment/src/internationalization/AppText';
import { i18n } from '@visikon/mytreatment/src/internationalization/i18n';
import { useCountry } from '@visikon/mytreatment/src/common/selectors';
import { DismissedPrograms, TreatmentCardStorage } from '@visikon/mytreatment/src/local-storage/treatment-card-storage';
import { tracker } from '@visikon/tracker/src';
import { ProgramsStorage, StoredPrograms } from '@visikon/mytreatment/src/local-storage/programs-storage';
import { useDispatch, useSelector } from 'react-redux';
import { setOperationDate } from '@visikon/mytreatment/src/actions/userActions';
import { usePromise } from '@visikon/mytreatment/src/common/utils';
import { Dispatch } from 'redux';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { IState } from '@visikon/mytreatment/src/reducers/reducer';

function I18NWithFallback({ keys }: { keys: TextKeys[] }): any {
  const language = useCountry().languageCode;

  for (const key of keys) {
    const translation = i18n(key, language);
    if (!translation.startsWith('mis: ')) return translation;
  }

  return `mis: ${keys[0]} (+${--keys.length})`;
}

function i18nOperationDate(operationTimestamp: number, language: LanguageCode) {
  const locale = mapLanguageToLocale(language);
  const operateDateDate = DateTime.fromMillis(operationTimestamp);
  const formatOptions: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };

  return operateDateDate.setLocale(locale).toLocaleString(formatOptions);
}

async function launchDateInput(dispatch: Dispatch<any> | undefined, programId: string, operationDate: number | undefined) {
  const datePicked = await PresentDatePicker(operationDate || DateTime.local().toMillis());

  if (datePicked) {
    const timestampMillis = datePicked.toMillis();
    handleOperationDate(dispatch, programId, timestampMillis);

    // Request permission - if already granted this will not re-prompt the user
    await LocalNotifications.requestPermissions();
  }
  console.log('No date picked - no update to treatment date');
}

function programExists(programId: string | undefined, programs?: DismissedPrograms | undefined) {
  if (!programId || !programs) return false;
  return programs.filter((p) => p[programId]).length !== 0;
}

function useOperationDate() {
  const { program, operationDate } = useBackbone((state) => ({
    program: state.program,
    operationDate: state.user?.operationDate,
  }));
  const programsData = useSelector((state: IState) => state.userData.programsData);
  const foundProgramData = programsData?.find((p) => p.id === program!.id);
  return foundProgramData?.operationDate || operationDate;
}

export function TreatmentDateContainer() {
  const programs = usePromise<StoredPrograms | undefined>(ProgramsStorage.get);
  const dismissedPrograms = usePromise<DismissedPrograms | undefined>(TreatmentCardStorage.get);
  const activeProgramId = programs?.activeProgramId;
  const programExist = programExists(activeProgramId, dismissedPrograms);
  const [cardDismissed, setCardDismissed] = React.useState(programExist);
  const operationDateToUse = useOperationDate();

  React.useEffect(() => {
    if (!programs || !activeProgramId) return;

    setCardDismissed(programExist);
  }, [dismissedPrograms]);

  const { env, program } = useBackbone((state) => ({
    program: state.program,
    operationDate: state.user?.operationDate,
    env: state.env,
  }));

  const hasNotifiaction = !!program?.notifications;
  const showOperationDate = program!.type !== 'information' && hasNotifiaction;

  // Only show in app or local dev environment
  const eligiblePlatform = Capacitor.getPlatform() !== 'web' || env === EnvironmentName.Local;

  if (!showOperationDate || !eligiblePlatform) {
    return null;
  }

  if (!cardDismissed && !operationDateToUse) {
    return <TreatmentDateCard program={program!} setDismiss={setCardDismissed} />;
  }

  return <TreatmentDateLabel />;
}

function TreatmentDateLabel() {
  const { program, language } = useBackbone((state) => ({
    program: state.program,
    operationDate: state.user?.operationDate,
    language: state.country.languageCode,
  }));
  const operationDateToUse = useOperationDate();

  return (
    <Styles.OperationDateLabelContainer>
      {operationDateToUse && <ShowTreatmentDate program={program!} operationDate={operationDateToUse} language={language} />}
      {!operationDateToUse && <AddTreatmentDateLabel program={program!} />}
    </Styles.OperationDateLabelContainer>
  );
}
interface ProgramProps {
  program: ProgramInfo;
}

function handleOperationDate(dispatch: Dispatch<any> | undefined, programId: string, operationDate: number) {
  console.log('handleOperationDate', operationDate);
  dispatch?.(setOperationDate({ programId, operationDate }));
}

function AddTreatmentDateLabel({ program }: ProgramProps) {
  const operationDate = useOperationDate();
  const dispatch = useDispatch();
  return (
    <Typography.Span onClick={(e) => launchDateInput(dispatch, program.id, operationDate)}>
      <Styles.OperationDateLabel to="#">
        <I18NWithFallback keys={[`set_date_${program.type}` as TextKeys, 'set_date_treatment']} />
      </Styles.OperationDateLabel>
    </Typography.Span>
  );
}

interface ShowTreatmentDateProps extends ProgramProps {
  operationDate: number;
  language: LanguageCode;
}

function ShowTreatmentDate({ program, operationDate, language }: ShowTreatmentDateProps) {
  const translation = I18NWithFallback({ keys: [`date_${program.type}` as TextKeys, 'date_treatment'] });
  const i18nTreatmentDate = i18nOperationDate(operationDate, language);
  const dispatch = useDispatch();
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  const operationDateToUse = useOperationDate();
  if (isMobileView) {
    return (
      <>
        <Typography.Span onClick={() => launchDateInput(dispatch, program.id, operationDate)}>
          <Styles.OperationDateLabel to="#">{translation + ': ' + i18nTreatmentDate}</Styles.OperationDateLabel>
        </Typography.Span>
      </>
    );
  }

  return (
    <>
      <Typography.Span>{translation + ': ' + i18nTreatmentDate}</Typography.Span>
      <Styles.EditTreatmentDateButton onClick={() => launchDateInput(dispatch, program.id, operationDate)}>
        <FontAwesomeIcon icon={faPen} size={'lg'} />
      </Styles.EditTreatmentDateButton>
    </>
  );
}

interface TreatmentCardProps extends ProgramProps {
  setDismiss: React.Dispatch<React.SetStateAction<boolean>>;
}

function TreatmentDateCard({ program, setDismiss }: TreatmentCardProps) {
  const dismissedPrograms = usePromise<DismissedPrograms>(TreatmentCardStorage.get) || [];
  const timeSnapshot = new Date().toISOString();
  const storedPrograms = usePromise<StoredPrograms | undefined>(ProgramsStorage.get);
  const activeProgramId = storedPrograms?.activeProgramId;
  const dispatch = useDispatch();
  const operationDate = useOperationDate();

  const dismiss = async () => {
    if (programExists(activeProgramId as string)) return;
    dismissedPrograms?.push({ [activeProgramId as string]: timeSnapshot });
    await TreatmentCardStorage.set(dismissedPrograms);
    setDismiss(true);
    tracker.trackEvent('UserData', 'TreatmentCard', 'dismiss');
  };

  return (
    <ActionCard>
      <Typography.H5>
        <I18NWithFallback keys={[`date_card_${program.type}_when` as TextKeys, 'date_card_treatment_when']} />
      </Typography.H5>
      <Typography.P size="small">
        <I18NWithFallback keys={[`date_card_${program.type}_info` as any, 'date_card_treatment_info']} />
      </Typography.P>
      <Button onClick={() => launchDateInput(dispatch, program.id, operationDate)} style={{ marginTop: '24px' }}>
        <Typography.P size="small" style={{ color: Tokens.color.white }}>
          <I18N text="date_card_enter" />
        </Typography.P>
      </Button>
      <Button variant="text" onClick={dismiss} style={{ background: 'transparent' }}>
        <Typography.P size="small" style={{ color: Tokens.color.primary }}>
          <I18N text="date_card_unknown" />
        </Typography.P>
      </Button>
    </ActionCard>
  );
}
