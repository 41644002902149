import * as React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import { ReactComponent as BackIcon } from '../images/svg/arrow_back.svg';
import { HeaderIcon } from './styled/HeaderIcon';
import { Link } from 'react-router-dom';
import { IState } from '../reducers/reducer';
import { useSelector } from 'react-redux';
import { SvgIcon } from '../images/svgIcon';
import { Logo } from './Logo';
import { useIsEmbedded } from '../reducers/resourcesReducer';
import { IonHeader, IonToolbar } from '@ionic/react';
import { EnvironmentName, getEnvName } from '@visikon/backbone/src/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import * as Styles from './HeaderBar.styles';
import { useWholeContent } from '../common/selectors';
import { isHomeScreen } from '../common/utils';
import { LanguageSelectorDialog } from '../internationalization/LanguageSelectorDialog';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { useCountryCodesFromProgramList, useProgramList } from '../screens/program/useProgramList';
import { getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { LocationHooks, useBackbone } from '@visikon/backbone';
import { DEBUG_ROUTE } from '../screens/debug';
import { useNavLinkHelper, useNavTitle } from '../common/NavigationHelper';
import { HOME_ROUTE } from '../screens/home/HomeScreen';
import { LogoutIcon } from './LogoutIcon';
import { isHomeLevel } from './MainContainer.styles';

interface IPropses {
  activeTab?: string;
  showBack: boolean;
}

type IProps = IPropses;

function goBack() {
  window.history.back();
}

export const TitleComponent: React.FC<
  PropsWithChildren & {
    showBack: boolean;
    onBackClick?: () => void;
    /**
     * @deprecated only used to support surveys, when surveys are to support the navigation structure, this should be removed
     */
    backTitle?: String;
  }
> = (props) => {
  const navBackTitle = LocationHooks.useNavBackTitle();
  const { title: pageTitle } = useNavTitle();
  // console.log('navBackTitle: ', navBackTitle, pageTitle);
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  return (
    <>
      {props.showBack && (
        <>
          <Styles.BackButton onClick={props.onBackClick || goBack}>
            <SvgIcon style={{ paddingRight: 8 }} Icon={BackIcon} width={11} height={18} aria-hidden />
            {(isMobileView && navBackTitle) || props.backTitle || pageTitle}
          </Styles.BackButton>
        </>
      )}
      {!props.showBack && props.children}
    </>
  );
};

function DebugHeaderIcon() {
  const programId = useBackbone((state) => state.program?.id);
  if (getEnvName() !== EnvironmentName.Local) return null;

  return (
    <HeaderIcon aria-hidden>
      <Link to={`/${programId}${DEBUG_ROUTE}`} style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faBug} />
        <div className="headerIcon-title">Debug</div>
      </Link>
    </HeaderIcon>
  );
}

export const HeaderBar = (props: IProps) => {
  const topMenuDisabled = useIsEmbedded();
  const hasContent = useSelector((state: IState) => state.resources.content !== null);
  const alternativeIcons = useSelector((state: IState) => state.resources.icons);
  const level = LocationHooks.useNavLevel();

  const content = useWholeContent();
  const { programList } = useProgramList();
  const countryCodesSupported = useCountryCodesFromProgramList(programList);
  const { goBackLogic } = useNavLinkHelper();

  const [homeCheck, setHomeCheck] = React.useState<boolean | null>(null);

  useEffect(() => {
    setHomeCheck(!!isHomeLevel(level));
  }, [level]);

  // We cannot alter shadow dom with css ...so we have to do it the hard way
  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      const toolBar = document.querySelector('ion-toolbar');
      if (toolBar) {
        // @ts-ignore
        toolBar.shadowRoot?.querySelector('.toolbar-container').setAttribute('style', 'contain: none; overflow: visible');
      }
    }, 300);
  }, []);

  const countryCodes = isHomeScreen() ? countryCodesSupported.map((c) => c.code) : content.translations.map((t) => getCountryByLanguageCode(t).code);
  const icons = alternativeIcons ? (
    <>{alternativeIcons} </>
  ) : (
    <>
      <DebugHeaderIcon />
      <LanguageSelectorDialog countryCodes={countryCodes} />
      <LogoutIcon />
    </>
  );

  return !hasContent ? (
    <></>
  ) : (
    <IonHeader translucent mode="ios">
      <IonToolbar mode="md">
        {homeCheck !== null && (
          <Styles.Container aria-label="Top navigation" isHidden={topMenuDisabled} showBack={props.showBack} isHome={!!homeCheck}>
            <Styles.LeftCell>
              <TitleComponent showBack={props.showBack} onBackClick={goBackLogic}>
                {!topMenuDisabled && (
                  <Link to={HOME_ROUTE} style={{ display: 'flex' }} aria-label="logo">
                    <Logo addedHeight={8} />
                  </Link>
                )}
              </TitleComponent>
            </Styles.LeftCell>

            <Styles.RightCell>{!topMenuDisabled && icons}</Styles.RightCell>
          </Styles.Container>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
