import { Content } from '@visikon/core-models';
import { Action, LanguageCode, Notifications } from '@visikon/core-models/content';
import { Translation } from '@visikon/core-models/languageTypes';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import {
  clearResourceContent,
  fetchProgram,
  fetchPrograms,
  fetchProgramsError,
  fetchProgramSuccess,
  setError,
  setIcons,
  setIsShowingPrivacyPolicy,
  setIsUpdating,
  setPrivacyPolicyAcceptedEvent,
  setProgramInfo,
  setShowArchivedPrograms,
} from '../actions/resourcesActions';
import { ObjectId, Timestamp } from '@visikon/core-models/base';
import { Content as IContent } from '../sagas/resourcesSaga';
import { ProgramInfo } from '../screens/startcode/api';
import { IState as reducerIState } from './reducer';
import { useSelector } from 'react-redux';
import { backbone, useBackbone } from '@visikon/backbone';
import { BackboneState } from '@visikon/backbone/src/store';
import { getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { setNavLink, unsetNavLink } from '../actions/userActions';

export type NavLink = {
  title?: string;
  path?: string;
  action?: () => void;
};
export interface IState {
  isLoading: boolean;
  isShowingPrivacyPolicy: boolean;
  privacyPolicyAcceptedEvent?: Function; // not serializable
  content?: MitforlobContent;
  programInfo?: ProgramInfo;
  history?: any;
  isUpdating: boolean;
  error?: string;
  showPreview?: boolean;
  showArchivedPrograms?: boolean;
  activeProgram?: string;
  icons?: Element[] | undefined;
  navLink?: NavLink;
}

export interface ContentData {
  _id: string;
  name: string;
  subName?: string;
  anatomy?: string;
  department: Department;
  programVideos: VideoPlaylist;
  phamplets: Phamplets;
  instructionVideos?: VideoPlaylist;
  trainingVideos?: VideoPlaylist;
  symptoms: SymptomsType;
  faq: FAQType;
  help: ContentContainerType;
  survey: SurveyType;
  notifications?: Notifications;
  contactText: Content.Text;
}

export type WithContentTypeNames = {
  menuLabel?: string;
  pageTitle?: string;
};

// TODO: Move to core-models
type MitforlobProgramTypes = 'operation' | 'examination' | 'treatment' | 'information';

export interface MitforlobContent {
  _id: string;
  language: LanguageCode;
  name: string;
  subName?: string;
  image?: Content.Image;
  anatomy?: string;
  department: Department;
  programType: MitforlobProgramTypes;
  translations: LanguageCode[];
  // programs: Programs;
  programVideos: Translation<VideoPlaylist>;
  phamplets: Translation<Phamplets>;
  instructionVideos: Translation<VideoPlaylist>;
  trainingVideos: Translation<VideoPlaylist>;
  symptoms: Translation<SymptomsType>;
  faq: Translation<FAQType>;
  help: Translation<ContentContainerType>;
  survey: Translation<SurveyType>;
  contactText: Translation<Content.Text>;
  genericHeader?: boolean;
  notifications: Translation<Notifications>;
  footer?: string;
}

export interface CustomTypeInstance<Data, TD> {
  _id: ObjectId;
  typeDescriptor: TD;
  createdAt: Timestamp;
  tags: Content.Tag[];
  translations: Array<{
    language: Content.LanguageCode;
    mediaVariation: ObjectId;
    lastModified: Timestamp;
    data: Data;
    _id?: string;
  }>;
}

export type MitforlobPack = Array<
  CustomTypeInstance<
    {
      content: ContentData;
      name: string;
      subName?: string;
      anatomy?: string;
      department: string;
      organization: string;
      logo: Content.Image;
      banner: Content.Image;
      contactText: Content.Text;
      programType: MitforlobProgramTypes;
      genericHeader?: boolean;
    },
    'mitforlobPack'
  >
>;

export type VideoSection = {
  sectionType?: 'grid' | 'list';
  sectionName: string;
  videoList: VideoInfoList;
};

export type VideoPlaylist = CustomTypeInstance<
  WithContentTypeNames & {
    name: string;
    sections: Array<VideoSection>;
  },
  'videoPlaylist'
>;

export type VideoInfoList = Array<VideoInfo>;

export type VideoInfo = {
  name: string;
  description?: string;
  autoPlay?: boolean;
  video: Video;
};

export interface Video extends Content.Video {
  trackingKey?: string;
}

export interface Department {
  name: string;
  organization: string;
  logo: Content.Image;
  banner: Content.Image;
}

export interface PhampletUrlType {
  _id: string;
  type: 'webLink';
  translations: Array<{
    language: Content.LanguageCode;
    webData: string;
  }>;
}
export function isUrlType(text: Content.Text | PhampletUrlType): text is PhampletUrlType {
  const tAny = text as any;
  return tAny.type !== undefined && tAny.type === 'webLink';
}

export type Phamplets = CustomTypeInstance<
  WithContentTypeNames & {
    documentName: string;
    textList: Array<{
      textTitle: string;
      text: Content.Text | PhampletUrlType;
      image?: Content.Image;
    }>;
  },
  'phamplets'
>;

export interface SymptomData {
  name: string;
  thumb?: Content.Image;
  infoList: Array<{
    text: string;
    subtext: string;
    severity: 'positive' | 'neutral' | 'negative';
    image?: Content.Image;
  }>;
}

export type SymptomsType = CustomTypeInstance<
  WithContentTypeNames & {
    title: string;
    description: string;
    image?: Content.Image;
    symptoms: SymptomData[];
  },
  'symptoms'
>;

export interface FAQSection {
  name: string;
  thumbnail: Content.Image;
  questions: Array<{
    question: string;
    answer: string;
  }>;
}

export interface SurveyQuestion {
  questionText: string;
  questionType: string;
  hasOtherField: boolean;
  answers: Array<{
    text: string;
    score: number;
  }>;
}

export type SurveyType = CustomTypeInstance<
  {
    title: string;
    description: string;
    questions: SurveyQuestion[];
    image?: Content.Image;
    invitationText?: string;
    notificationText?: string;
    offset: number;
  },
  'survey'
>;

export type ContentContainer = WithContentTypeNames & {
  title: string;
  description: string;
  collapsible?: boolean;
  image?: Content.Image;
  content: IContent[];
};

export type ContentContainerType = CustomTypeInstance<ContentContainer, 'help'>;

export type FAQType = CustomTypeInstance<
  WithContentTypeNames & {
    image?: Content.Image;
    title: string;
    sections: FAQSection[];
  },
  'faq'
>;

const initialState: IState = {
  isLoading: true,
  isShowingPrivacyPolicy: false,
  isUpdating: false,
  error: undefined,
  showArchivedPrograms: false,
  navLink: undefined,
};

export const isToggled = (feature: Action['payload']) => (state: reducerIState) => {
  const { content, programInfo } = state.resources;
  if (content?.name !== programInfo?.name) {
    return false;
  }
  return programInfo?.actions?.filter((a) => a.type === 'TOGGLE').find((a) => a.payload === feature) !== undefined;
};

export const isFullScreenAction = (videoId: string) => (state: reducerIState) => {
  const { content, programInfo } = state.resources;
  if (content?.name !== programInfo?.name) {
    return false;
  }
  return programInfo?.actions?.filter((a) => a.type === 'VIDEO_FULLSCREEN').find((a) => a.payload === videoId) !== undefined;
};

export const useIsEmbedded = () => {
  const externalUserSet = useSelector((state: reducerIState) => state.auth.externalUserSet);
  const toggled = useSelector(isToggled('topmenu'));
  const backboneEmbed = useBackbone((state) => state.embedInfo !== undefined);
  return backboneEmbed || externalUserSet || toggled;
};

export const useHasContent = () => {
  return useSelector((state: reducerIState) => state.resources.content !== undefined);
};

export const resourcesReducer: Reducer<IState> = (state = initialState, action) => {
  if (isType(action, fetchPrograms)) {
    return {
      ...state,
      isLoading: true,
      content: undefined,
    };
  }

  if (isType(action, fetchProgramsError)) {
    return { ...state, isLoading: false };
  }

  if (isType(action, fetchProgramSuccess)) {
    const content = action.payload;

    updateBackbone(content);

    return { ...state, content, isLoading: false };
  }

  if (isType(action, fetchProgram)) {
    return {
      ...state,
      isLoading: true,
      showPreview: action.payload.showPreview,
      activeProgram: action.payload.id,
    };
  }

  if (isType(action, clearResourceContent)) {
    return { ...initialState };
  }

  if (isType(action, setIsShowingPrivacyPolicy)) {
    return { ...state, isShowingPrivacyPolicy: action.payload };
  }

  if (isType(action, setPrivacyPolicyAcceptedEvent)) {
    return { ...state, privacyPolicyAcceptedEvent: action.payload };
  }

  if (isType(action, setProgramInfo)) {
    return { ...state, programInfo: action.payload.ProgramInfo, history: action.payload.history };
  }

  if (isType(action, setIsUpdating)) {
    return { ...state, isUpdating: true };
  }

  if (isType(action, setError)) {
    return { ...state, error: action.payload };
  }

  if (isType(action, setIcons)) {
    return { ...state, icons: action.payload };
  }

  if (isType(action, setShowArchivedPrograms)) {
    return { ...state, showArchivedPrograms: action.payload };
  }
  if (isType(action, setNavLink)) {
    // console.log('setNavLink', action.payload);
    return { ...state, navLink: action.payload };
  }
  if (isType(action, unsetNavLink)) {
    return { ...state, navLink: undefined };
  }
  return state;
};

function updateBackbone(translation: MitforlobContent) {
  const { department, notifications } = translation;
  const { name, organization } = department || {};

  const backboneUpdate = {
    program: { id: translation._id, department: name, organization, notifications, name, type: translation.programType },
  } as Partial<BackboneState>;

  if (backbone.store.getState().country.languageCode !== translation.language) {
    const country = getCountryByLanguageCode(translation.language);
    console.log(`Changing country to '${country.name}'`);
    backboneUpdate.country = country;
  }

  backbone.update(backboneUpdate);
}
