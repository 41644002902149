import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import {
  archiveProgram,
  initializeUserInfo,
  resetSeenPrivacyPolicy,
  restoreProgram,
  setNewProgram,
  setOperationDate,
  setProgramsData,
  setSeenPrivacyPolicy,
  setUserData,
  toggleFavorite,
  unsetNewProgram,
  updateVideoProgress,
} from '../actions/userActions';
import { surveySubmit } from '../actions/surveyActions';
import { backbone } from '@visikon/backbone';
import { BackboneState } from '@visikon/backbone/src/store';
export interface IVideoProgress {
  id: string;
  percentage: number;
}

export type ProgramData = {
  id: string;
  order: number;
  archived: boolean;
  isNew: boolean;
  operationDate?: number;
};

export interface IUserDataState {
  userInfo: {
    operationDate?: number;
    name?: string;
  };
  videoProgress: IVideoProgress[];
  lastUpdate?: number;
  favorites: string[];
  surveyAnswered?: string[];
  seenPrivacyPolicy?: Date;
  programsData?: ProgramData[];
}

export const initialState: IUserDataState = {
  userInfo: {},
  favorites: [],
  videoProgress: [],
  surveyAnswered: [],
  seenPrivacyPolicy: undefined,
};

export const userDataReducer: Reducer<IUserDataState> = (state = { ...initialState }, action) => {
  // User seen privacy policy
  if (isType(action, setSeenPrivacyPolicy)) {
    return { ...state, seenPrivacyPolicy: action.payload };
  }

  if (isType(action, resetSeenPrivacyPolicy)) {
    return { ...state, seenPrivacyPolicy: undefined };
  }

  if (isType(action, updateVideoProgress)) {
    const otherVideosProgress = state.videoProgress.filter((n) => n.id !== action.payload.id);
    const videoProgress = [...otherVideosProgress, action.payload];
    return { ...state, videoProgress };
  }
  // Save survey id after answer
  if (isType(action, surveySubmit)) {
    const surveyAnswered = state.surveyAnswered ? [...state.surveyAnswered, action.payload.id] : [action.payload.id];
    return { ...state, surveyAnswered };
  }

  if (isType(action, toggleFavorite)) {
    const wasActive = state.favorites.some((id) => id === action.payload);
    // Remove id
    if (wasActive) {
      return {
        ...state,
        favorites: state.favorites.filter((id) => id !== action.payload),
      };
    }
    // Add id
    return {
      ...state,
      favorites: [...state.favorites, action.payload],
    };
  }

  if (isType(action, initializeUserInfo)) {
    backbone.update((state) => {
      const { userInfo } = action.payload;
      return syncUserState(state, userInfo, 'operationDate');
    });

    return { ...state, ...action.payload };
  }

  // if (isType(action, setOperationDate)) {
  //   const userInfo = { ...state.userInfo, operationDate: action.payload };
  //
  //   return { ...state, ...{ userInfo } };
  // }

  if (isType(action, setUserData)) {
    return { ...initialState, ...action.payload, lastUpdate: Date.now() };
  }

  const newProgramsData = state.programsData ? [...state.programsData] : [];
  const program = newProgramsData.find((programData) => programData.id === action.payload);

  if (isType(action, archiveProgram)) {
    if (program) {
      program.archived = true;
      program.order = new Date().getTime();
    }
    return { ...state, programsData: newProgramsData };
  }

  if (isType(action, restoreProgram)) {
    if (program) {
      program.archived = false;
      program.order = new Date().getTime();
    }
    return { ...state, programsData: newProgramsData };
  }

  if (isType(action, setNewProgram)) {
    if (program) {
      program.isNew = true;
    } else {
      newProgramsData.push({ id: action.payload, order: new Date().getTime(), isNew: true, archived: false });
    }
    return { ...state, programsData: newProgramsData };
  }

  if (isType(action, unsetNewProgram)) {
    if (program) {
      program.isNew = false;
    }
    return { ...state, programsData: newProgramsData };
  }

  if (isType(action, setProgramsData)) {
    action.payload.forEach((programDescription) => {
      if (!newProgramsData.find((programData) => programData.id === programDescription._id)) {
        newProgramsData.push({ id: programDescription._id, order: new Date().getTime(), isNew: false, archived: false });
      }
    });
    return { ...state, programsData: newProgramsData };
  }

  if (isType(action, setOperationDate)) {
    const newProgramsData = state.programsData ? [...state.programsData] : [];
    const program = newProgramsData.find((programData) => programData.id === action.payload.programId);

    if (program) {
      program.operationDate = action.payload.operationDate;
    }
    const userInfo = { ...state.userInfo, operationDate: undefined };
    return { ...state, ...{ userInfo }, programsData: newProgramsData };
  }

  // N.B. clearUserData is handled in the saga
  return state;
};

type KEY = keyof IUserDataState['userInfo'];

function syncUserState(state: BackboneState, userInfo: IUserDataState['userInfo'], keyToUpdate: KEY) {
  if (!userInfo || !state.user) {
    return state;
  }
  return { ...state, user: { ...state.user, [keyToUpdate]: userInfo[keyToUpdate] } };
}
