import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action, isType } from 'typescript-fsa';
import { authenticateSuccessful, logOut } from '../actions/authActions';
import {
  clearUserData,
  initializeUserInfo,
  toggleFavorite,
  updateVideoProgress,
  setSeenPrivacyPolicy,
  setOperationDate,
  setUserData,
  archiveProgram,
  restoreProgram,
  setProgramsData,
  unsetNewProgram,
} from '../actions/userActions';
import { LOCAL_STORAGE_USER_DATA_KEY, MAX_USER_DATA_AGE } from '../common/config';
import { IAuthSuccessResult } from '../reducers/authReducer';
import { IState } from '../reducers/reducer';
import { initialState, IUserDataState } from '../reducers/userDataReducer';
import { tracker } from '@visikon/tracker/src';
import { surveySubmit } from '../actions/surveyActions';
import { fetchGet, fetchPost } from '@visikon/utils/src/utils';
import { GetStorage } from '../local-storage/Storage';
import { backbone } from '@visikon/backbone';

/**
 * This saga listens to all actions which changes the user data.
 * Saves to the cloud.
 *
 * Relevant data is:
 *   - UserInfo: op date (???, GDPR)
 *   - NotificationSettings
 */

// Selector functions:

const getUserData = (state: IState) => state.userData;

function trackUserDataChange(action: Action<any>, favorites: string[]) {
  if (isType(action, setSeenPrivacyPolicy)) {
    const dateString = new Date(action.payload).toISOString();
    tracker.trackEvent('UserData', 'privacyPolicySeen', dateString);
  }

  if (isType(action, toggleFavorite)) {
    tracker.trackEvent('UserData', 'toggleFavorites', favorites.join(';'));
  }
}

function* updateUserDataSaga(action: Action<void>) {
  const { lastUpdate, ...userData } = yield select(getUserData);
  const { favorites } = userData;

  trackUserDataChange(action, favorites);
  try {
    if (!backbone.isEmbed()) {
      yield call(fetchPost, 'users/userdata', {}, userData);
    }
    yield put(setUserData(userData));
  } catch (e: any) {
    console.error("Couldn't update user data");
  }
}

function* initiateUserDataSaga(action: Action<IAuthSuccessResult>) {
  const { lastUpdate, ...userDataEmptyState } = yield select(getUserData);

  let userData = userDataEmptyState; // From state

  if (action.payload.userData) {
    userData = action.payload.userData; // From LocalStorage
  }
  const dataAge = Date.now() - (userData.lastUpdate || 0);
  if (!backbone.isEmbed() && dataAge > MAX_USER_DATA_AGE) {
    // If data from local storage is too old - update from net!

    const result: IUserDataState = yield call(fetchGet, 'users/userdata');
    if (result) {
      result.lastUpdate = Date.now();
      userData = result;
    }
  }

  yield put(setUserData(userData));
  yield put(initializeUserInfo(userData));
}

function* clearUserDataSaga() {
  yield put(setUserData(initialState));
}
function* setUserDataLocalStorage(action: Action<IUserDataState>) {
  yield GetStorage().setItem(LOCAL_STORAGE_USER_DATA_KEY, JSON.stringify(action.payload));
}

export function* userDataSaga() {
  // Post data to server
  yield takeEvery(updateVideoProgress as any, updateUserDataSaga);
  yield takeEvery(toggleFavorite as any, updateUserDataSaga);
  yield takeEvery(setSeenPrivacyPolicy as any, updateUserDataSaga);
  yield takeEvery(surveySubmit as any, updateUserDataSaga);
  yield takeEvery(setOperationDate as any, updateUserDataSaga);
  yield takeEvery(archiveProgram as any, updateUserDataSaga);
  yield takeEvery(restoreProgram as any, updateUserDataSaga);
  yield takeEvery(unsetNewProgram as any, updateUserDataSaga);
  yield takeEvery(setProgramsData as any, updateUserDataSaga);

  // Re-insert data
  yield takeEvery(authenticateSuccessful, initiateUserDataSaga);

  // Remove user data on logOut
  yield takeEvery(logOut as any, clearUserDataSaga);

  // Update redux state from get userData
  yield takeEvery(setUserData, setUserDataLocalStorage);
  yield takeEvery(clearUserData, clearUserDataSaga);
}
